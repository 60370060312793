"use client";

import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import { useState } from "react";

import { markets } from "~app/config";
import { Icon } from "~components/ui";
import { ampli } from "~lib/ampli";
import { type PageContext, getLinkProps } from "~lib/storyblok";
import { cn } from "~utils";
import { useWindowScroll } from "~utils/scroll";

import agreenaLogo from "../../../../public/agreena-logo.svg";
import styles from "./desktop-nav.module.css";
import type { HeaderBlok, HeaderNavValues, NavItemBlok } from "./types";

type DesktopNavProps = {
  blok: HeaderBlok | undefined;
  initialValue?: HeaderNavValues;
} & PageContext;

export function DesktopNav({
  blok,
  initialValue,
  ...context
}: DesktopNavProps) {
  const [value, setValue] = useState(initialValue);

  const {
    isAtStart: isScrollAtStart,
    direction: scrollDirection,
    lastDirection: lastScrollDirection,
  } = useWindowScroll({ startThreshold: 100, minSpeed: 100 });

  /**
   * Hides the nav when we scroll down past the first 100px of the window,
   * and shows it again when scrolling up.
   * Any scroll slower than 100px per second is ignored, to prevent "accidental"
   * scrolls.
   */
  const isHidden =
    !value &&
    !isScrollAtStart &&
    (scrollDirection === "down" || lastScrollDirection === "down");

  const currentMarket = markets.find(item => item.key === context.marketId);

  if (!blok) return null;

  const homeLinkProps = getLinkProps(blok.homeLink, context);
  const signupLinkProps = getLinkProps(blok.headerSignupLink, context);
  const loginLinkProps = getLinkProps(blok.headerLoginLink, context);

  return (
    <header
      {...storyblokEditable(blok)}
      className={styles.header}
      data-state={value ? "open" : "closed"}
      data-value={value}
      data-hidden={isHidden}
    >
      <NavigationMenu.Root
        className={styles.nav}
        value={value}
        onValueChange={newValue => setValue(newValue as HeaderNavValues)}
        orientation="horizontal"
      >
        <NavigationMenu.List className={styles.list}>
          <NavigationMenu.Item className={styles["logo-item"]} value="logo">
            <NavigationMenu.Link
              {...homeLinkProps}
              onClick={() => {
                if (!homeLinkProps.href) return;

                ampli.navigationLinkClicked({
                  navigationArea: "header",
                  navigationLinkHref: homeLinkProps.href,
                });
                ampli.flush();
              }}
            >
              <Image src={agreenaLogo} height={32} alt="Agreena logo" />
            </NavigationMenu.Link>
          </NavigationMenu.Item>

          <HeaderLinkGroupItem
            text={blok.headerFarmersText}
            items={blok.headerFarmersItems}
            {...context}
            value="farmers"
          />

          <HeaderLinkGroupItem
            text={blok.headerCompaniesText}
            items={blok.headerCompaniesItems}
            {...context}
            value="companies"
          />

          <HeaderLinkGroupItem
            text={blok.headerResourcesText}
            items={blok.headerResourcesItems}
            {...context}
            value="resources"
          />

          <HeaderLinkGroupItem
            text={blok.headerMoreText}
            items={blok.headerMoreItems}
            {...context}
            value="more"
          />

          <NavigationMenu.Item
            className={cn("section-sky", styles["login-item"])}
          >
            <NavigationMenu.Link
              className={styles["login-link"]}
              {...loginLinkProps}
              onClick={() => {
                if (!loginLinkProps.href) return;

                ampli.navigationLinkClicked({
                  navigationArea: "header",
                  navigationLinkHref: loginLinkProps.href,
                });
                ampli.flush();
              }}
            >
              {blok.headerLoginText}
            </NavigationMenu.Link>
          </NavigationMenu.Item>

          <NavigationMenu.Item
            className={styles["markets-item"]}
            value="markets"
          >
            {/* Custom one-off button style in mini size with gray background */}
            <NavigationMenu.Trigger
              className={styles["markets-trigger"]}
              type="button"
            >
              <Image
                src={currentMarket?.icon}
                width="24"
                height="24"
                alt={currentMarket?.name ?? ""}
              />
              <span>
                <Icon name="CaretDown" weight="bold" />
              </span>
            </NavigationMenu.Trigger>

            <NavigationMenu.Content className={styles["markets-content"]}>
              {blok.headerMarketsText && (
                <p className={styles["markets-text"]}>
                  {blok.headerMarketsText}
                </p>
              )}

              {markets.map(market => (
                <a
                  key={market.key}
                  href={market.url}
                  className={styles["market-link"]}
                >
                  <Image src={market.icon} alt={market.name} />
                  <span>{market.name}</span>
                </a>
              ))}
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        </NavigationMenu.List>

        <NavigationMenu.Viewport className={styles.viewport} />
      </NavigationMenu.Root>
    </header>
  );
}

type NavGroupProps = {
  text?: string;
  items: NavItemBlok[] | null;
  value: string;
} & PageContext;

function HeaderLinkGroupItem({
  items,
  text,
  value,
  ...context
}: NavGroupProps) {
  if (!items?.length) return null;

  return (
    <NavigationMenu.Item
      className={styles["link-group-item"]}
      value={value}
      data-value={value}
    >
      <NavigationMenu.Trigger className={styles["link-group-trigger"]}>
        <div>
          <span>{text}</span>
          <Icon name="CaretDown" weight="bold" />
        </div>
      </NavigationMenu.Trigger>

      <NavigationMenu.Content className={styles["link-group-content"]}>
        {items.map(item => {
          const linkProps = getLinkProps(item.link, context);

          return (
            <a
              {...linkProps}
              {...storyblokEditable(item as SbBlokData)}
              key={item._uid}
              className={styles["nav-link"]}
              // biome-ignore lint/a11y/useValidAnchor: This is a correct link, we just need extra listener
              onClick={() => {
                if (!linkProps.href) return;

                ampli.navigationLinkClicked({
                  navigationArea: "header",
                  navigationLinkHref: linkProps.href,
                });
                ampli.flush();
              }}
            >
              <Icon name={item.icon} weight="duotone" />

              <div>
                <div
                  className={cn(
                    !item.text && "!t-default-sm",
                    styles["nav-link-title"],
                  )}
                >
                  {item.title}
                </div>

                {item.text && (
                  <p className={styles["nav-link-text"]}>{item.text}</p>
                )}
              </div>
            </a>
          );
        })}
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
}
